import React from 'react';
import './App.css';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import theme from './setupThemes';
import AuthProvider from './core/user/AuthProvider';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import AppLayout from './components/layouts/AppLayout';
import Login from './components/auth/Login';
import Logout from './components/auth/Logout';
import ResetPassword from './components/auth/ResetPassword';
import PageNotFound from './components/layouts/PageNotFound';
import ProtectedRoute from './protectedRoutes/ProtectedRoute';
import ContestManagerRoute from './protectedRoutes/ContestManagerRoute';
import ContestList from './components/contests/ContestList';
import ContestDashboard from './components/contests/ContestDashboard';
import ContestStandings from './components/contests/ContestStandings';
import ContestSubmissions from './components/contests/ContestSubmissions';
import ContestClarifications from './components/contests/ContestClarifications';
import ProblemPage from './components/contests/ProblemPage';
import ManageContests from './components/manager/ManageContests';
import CreateContest from './components/manager/CreateContest';
import EditContest from './components/manager/EditContest';
import EditProblemSet from './components/manager/EditProblemSet';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import { SnackbarProvider } from 'notistack';
import SubmissionDetailView from './components/contests/ContestSubmissions/SubmissionDetailView';
import NotAuthorized from './components/layouts/NotAuthorized';
import ContestJudgeRoute from './protectedRoutes/ContestJudgeRoute';
import ContestNotes from './components/contests/ContestNotes';
import { TitleProvider } from './contexts/TitleContext';
import UpdatePassword from './components/auth/UpdatePassword';

export default function App() {
	return (
		<>
			<TitleProvider>
				<ThemeProvider theme={theme}>
					<CssBaseline />
					<AuthProvider>
						<LocalizationProvider dateAdapter={AdapterMoment}>
							<SnackbarProvider maxSnack={5} anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
								<BrowserRouter>
									<Routes>
										<Route path='' element={<AppLayout />}>
											{/*<Route path='/' element={<Homepage />} />*/}
											<Route path='/' element={<ContestList />} />
											<Route path='/login' element={<Login />} />
											<Route path='/reset' element={<ResetPassword />} />
											<Route path='/logout' element={<Logout />} />
											<Route path='/contests' element={<ContestList />} />
											<Route path='/contests/:contestSlug' element={<ContestDashboard />} />
											<Route path='/contests/:contestSlug/standings' element={<ContestStandings />} />
											<Route path='/contests/:contestSlug/notes' element={<ContestNotes />} />

											<Route element={<ProtectedRoute />}>
												<Route path='/contests/:contestSlug/problems/:problemCharacter/' element={<ProblemPage />} />
												<Route path='/contests/:contestSlug/submissions' element={<ContestSubmissions />} />
												<Route
													path='/contests/:contestSlug/submissions/:submissionId'
													element={<SubmissionDetailView />}
												/>
												<Route path='/contests/:contestSlug/clarifications' element={<ContestClarifications />} />
												<Route path='/user/update_password/' element={<UpdatePassword />} />
											</Route>

											<Route element={<ContestManagerRoute />}>
												<Route path='/admin' element={<ManageContests />} />
												<Route path='/contests/create' element={<CreateContest />} />
											</Route>

											<Route element={<ContestJudgeRoute />}>
												<Route path='/contests/:contestSlug/edit' element={<EditContest />} />
												<Route
													path='/contests/:contestSlug/problems/:problemCharacter/edit'
													element={<EditProblemSet />}
												/>
												<Route
													path='/contests/:contestSlug/internal/standings'
													element={<ContestStandings internal={true} />}
												/>
											</Route>

											<Route path='/403' element={<NotAuthorized />} />
											<Route path='/404' element={<PageNotFound />} />
											<Route path='*' element={<PageNotFound />} />
										</Route>
									</Routes>
								</BrowserRouter>
							</SnackbarProvider>
						</LocalizationProvider>
					</AuthProvider>
				</ThemeProvider>
			</TitleProvider>
		</>
	);
}
